import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import Portfolio from "../components/Portfolio";
import GSA from "../misc/Capabilities-statement-2023-alt-edt-v2.pdf";

const Terms = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GSA Capabilities | Consult FGC Digital Marketing Agency </title>
        <meta
          name="description"
          content="Our company's core compentencies and capabilities for GSA proposals and purposes."
        />
        <link
          rel="icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        />
      </Helmet>
      <div className="bg-white pt-16 pb-10 px-4 sm:px-6 lg:pt-14  lg:px-8">
        <div className="relative max-w-lg mx-auto lg:max-w-7xl">
          <div>
            <h2 className="text-center text-3xl leading-9 tracking-tight font-extrabold text-blue-700 sm:text-5xl sm:leading-10">
              GSA Capabilities
            </h2>
          </div>
          <div className="mt-12 gap-16 border-t border-gray-200 pt-12  lg:row-gap-24">
            <div className="mx-auto lg:w-1/2"></div>
          </div>
        </div>
      </div>

      <div class="max-w-7xl mx-auto relative bg-white pb-20 overflow-hidden">
        <div class="relative">
          <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div class="mt-0 md:mt-6">
                  <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                    Company Overview
                  </h2>
                  <p class="mt-4 text-lg text-gray-500">
                   Located in the Tampa Bay, Florida area, Consult F.G.C. is a 
full-service digital marketing and advertising, design, 
development, and geofence advertising firm. Founded in 2016, 
the company is a small business with a proven track record of 
successfully delivering projects on time and within budget. Our 
team has a combined forty years of experience.
                  </p>
                  <div class="mt-6">
                    <a
                      href={GSA}
                      target="blank"
                      class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700"
                    >
                      Download Capability Statement
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="hidden md:block mt-12 sm:mt-16 lg:mt-0">
              <div class=" pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src="https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  alt="Business Discussion"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-24">
          <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div class="mt-6">
                  <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                    Why work with us
                  </h2>
                  <p class="mt-4 text-lg text-gray-500">
                    <span class="font-bold">Connection:</span> Our ability to understand non-technical business 
requirements and translate that into functional solutions that 
solve problems. 
                  </p>
                   <p class="mt-4 text-lg text-gray-500">
                    <span class="font-bold">Communication:</span> We make sure our clients are fully in-the-loop
consistently.
                  </p>
                   <p class="mt-4 text-lg text-gray-500">
                    <span class="font-bold">Conversions: </span>We are results-oriented. Our goal is to help you 
achieve your goals and make you look good.
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src="https://images.pexels.com/photos/6224/hands-people-woman-working.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  alt="Design Project Layout"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-20 gap-16 border-t border-gray-200">
          <div className="mx-auto lg:w-1/2"></div>
        </div>
      </div>
      <div class="relative bg-white ">
        <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            Core Competencies
          </p>

          <div class="mt-12">
            <div class="grid grid-cols-1 gap-8 sm:grid-cols-2">
              <div class="pt-6">
                <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-3 bg-cyan-500 rounded-md shadow-lg">
                        <svg
                          class="h-6 w-6 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M1 12.155c2.256 3.97 4.55 7.918 6.879 11.845h-5.379c-.829 0-1.5-.675-1.5-1.5v-10.345zm2.85.859c3.278 1.952 12.866 7.658 13.121 7.805l-5.162 2.98c-.231.132-.49.201-.751.201-.549 0-1.037-.298-1.299-.75l-5.909-10.236zm1.9-12.813c-.23-.133-.489-.201-.75-.201-.524 0-1.026.277-1.299.75l-3.5 6.062c-.133.23-.201.489-.201.749 0 .527.278 1.028.75 1.3 2.936 1.695 14.58 8.7 17.516 10.396.718.413 1.633.168 2.048-.55l3.5-6.062c.133-.23.186-.488.186-.749 0-.52-.257-1.025-.734-1.3l-17.516-10.395m.25 3.944c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2" />
                        </svg>
                      </span>
                    </div>
                    <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                     Digital Marketing & Advertising
                    </h3>
                    <div class="grid md:grid-cols-2 text-left">
                    <p class="mt-5 text-base text-gray-500">
                     + Geofence Advertising
                    </p>
                    <p class="mt-5 text-base text-gray-500">
                      + Pay-per-click (PPC) Advertising
                    </p>
                     <p class="mt-5 text-base text-gray-500">
                      + Advertising Strategy
                    </p>
                      <p class="mt-5 text-base text-gray-500">
                       + Programmatic Advertising
                    </p>
                    <p class="mt-5 text-base text-gray-500">
                      + Search Engine Marketing
                    </p>
                     <p class="mt-5 text-base text-gray-500">
                     + Search Engine Optimization
                    </p>
                      <p class="mt-5 text-base text-gray-500">
                     + Social Media Marketing
                    </p>
                      <p class="mt-5 text-base text-gray-500">
                    + Reporting & Data Visualization
                    </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pt-6">
                <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-3 bg-cyan-500 rounded-md shadow-lg">
                        <svg
                          class="h-6 w-6 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M13.927 23.84c-.638.105-1.292.16-1.959.16-6.606 0-11.968-5.377-11.968-12s5.362-12 11.968-12c6.605 0 11.967 5.377 11.967 12 0 .671-.055 1.329-.161 1.971l-1.917-.675c.055-.424.084-.857.084-1.296 0-5.519-4.469-10-9.973-10-5.505 0-9.973 4.481-9.973 10s4.468 10 9.973 10c.45 0 .894-.03 1.328-.088l.631 1.928zm-1.173-10.001c-.242.103-.507.161-.786.161-1.101 0-1.995-.896-1.995-2s.894-2 1.995-2c1.1 0 1.994.896 1.994 2 0 .211-.032.414-.093.604l9.733 3.426-3.662 1.926 4.06 4.173-1.874 1.827-4.089-4.215-2.167 3.621-3.116-9.523zm-.093 6.131c-.229.02-.46.03-.693.03-4.404 0-7.979-3.585-7.979-8s3.575-8 7.979-8c4.403 0 7.978 3.585 7.978 8 0 .207-.008.412-.023.615l-.979-.344.005-.271c0-3.863-3.128-7-6.981-7-3.853 0-6.982 3.137-6.982 7s3.129 7 6.982 7l.372-.01.321.98zm-.975-2.978c-2.621-.146-4.705-2.327-4.705-4.992 0-2.76 2.234-5 4.987-5 2.605 0 4.747 2.008 4.967 4.564l-1.06-.373c-.374-1.821-1.982-3.191-3.907-3.191-2.202 0-3.99 1.792-3.99 4 0 1.996 1.461 3.652 3.368 3.952l.34 1.04z" />
                        </svg>
                      </span>
                    </div>
                    <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                     Web Design & Development
                    </h3>
                  <div class="grid md:grid-cols-2 text-left md:pl-5">
                    <p class="mt-5 text-base text-gray-500">
                     + Adobe Creative Suite
                    </p>
                    <p class="mt-5 text-base text-gray-500">
                     + Jamstack App Development
                    </p>
                     <p class="mt-5 text-base text-gray-500">
                     + Wordpress
                    </p>
                      <p class="mt-5 text-base text-gray-500">
                        + AWS/MERN Stack
                    </p>
                    <p class="mt-5 text-base text-gray-500">
                      + Gatsby
                    </p>
                     <p class="mt-5 text-base text-gray-500">
                    + Tailwind CSS
                    </p>
                      <p class="mt-5 text-base text-gray-500">
                      + React
                    </p>
                      <p class="mt-5 text-base text-gray-500">
                    + Node
                    </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="logos">
        <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <p class="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
            Trusted by several businesses along multiple industries.
          </p>
          <Portfolio />
        </div>
      </div>

      <div class="max-w-7xl mx-auto mb-20 flex flex-col items-center justify-center gap-8">
        <h1 class="text-md px-4 md:text-3xl text-gray-600 leading-relaxed text-center w-full">
          "What we do is called 'security awareness training'. We hired Jason
          and his team to do our Search Engine Optimization and Social Media
          distribution. Our goal was to make it onto Google Page number one and
          be in the top three organic search results. Well, they delivered,
          because four months later we were there! This is an awesome result. We
          get visibly higher volume quality leads in the door. Thanks guys!"
        </h1>
        <div class="flex items-center gap-4">
          <div class="rounded-full w-12 h-12">
            <img src="https://media-exp2.licdn.com/dms/image/C4E03AQFVO37BTgc1fg/profile-displayphoto-shrink_200_200/0/1516244393797?e=1661990400&v=beta&t=CbOyiUpy5m9kpheBul5_U4btDY5NhUOpKBe30dxC0OE" />
          </div>
          <div class="flex flex-col tracking-wider">
            <label class="text-gray-600 font-bold text-base">
              Stu Sjouwerman
            </label>
            <label class="text-gray-400 font-normal text-sm">KnowBe4</label>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Terms;
